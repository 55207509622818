import React, { useState, useEffect } from 'react';
import { GrAddCircle } from "react-icons/gr"; // Importing GrAddCircle icon
import axios from 'axios'; // Importing axios for HTTP requests

function CreateEventForm({ initialDate, onCreate, event }) {
    // Function to format date to YYYY-MM-DD
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // State variables
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [notes, setNotes] = useState(''); // New state variable for notes
    const [isFormValid, setIsFormValid] = useState(false);
    const [tag, setTag] = useState('');
    const [tags, setTags] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    // Effect hook to initialize form fields with event data or initialDate
    useEffect(() => {
        if (event) {
            setTitle(event.title);
            setDate(formatDate(new Date(event.date)));
            setStartTime(event.startTime); // Format HH:mm
            setEndTime(event.endTime); // Format HH:mm
            setNotes(event.notes || ''); // Initialize notes if present in event data
            setTags(event.tags || []); // Initialize tags if present in event data
        } else if (initialDate) {
            setDate(formatDate(initialDate));
        }
    }, [event, initialDate]);

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Convert start time and end time to timestamps

        const newEvent = {
            title,
            date: date.replace(/-/g, "/"),
            startTime: startTime,
            endTime: endTime,
            notes, // Include notes in the new event data
            tags // Include tags in the new event data
        };

        try {
            if (event) {
                // Update existing event
                await axios.put(`https://api.jeremiah.business/objectapi/event-tracker/events/${event._id.$oid}`, newEvent);
                onCreate(newEvent);
                // Reset form fields after update
                setTitle('');
                setDate(formatDate(new Date()));
                setStartTime('');
                setEndTime('');
                setNotes('');
            } else {
                // Create new event
                await axios.post('https://api.jeremiah.business/objectapi/event-tracker/events', newEvent);
                onCreate();
            }

        } catch (error) {
            console.error("Error:", error);
        }
    };

    // Effect hook to update form validity
    useEffect(() => {
        setIsFormValid(title !== '' && date !== '' && startTime !== '' && endTime !== '');
    }, [title, date, startTime, endTime]);

    // Function to handle tag input change
    const handleTagsChange = async (e) => {
        const inputValue = e.target.value;
        setTag(inputValue);
        let tags = await axios.get('https://api.jeremiah.business/objectapi/event-tracker/tags');
        tags = tags.data.objects.map(item => item.tag);

        // Filter the options based on the input value
        const filtered = tags.filter(option =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    // Function to add a tag
    const addTag = () => {
        if (tag.trim() === "") {
            return; // Prevent adding empty tags
        }

        // Check if the tag already exists
        if (tags.includes(tag)) {
            // Tag already exists, no need to add it again
            setTag('');
            return;
        }

        // Add the tag to the tags state
        setTags([...tags, tag]);
        setTag('');
    };

    // Function to remove a tag
    const removeTag = (indexToRemove) => {
        setTags(prevTags => prevTags.filter((_, index) => index !== indexToRemove));
    };

    return (
        <div>
            {/* Form */}
            <div style={{ width: '300px' }} className="">
                <h2>{event ? 'Edit Event' : 'New Event'}</h2>

                <form onSubmit={handleSubmit} className="uk-form-stacked">
                    {/* Title input */}
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="title">Title:</label>
                        <input className="uk-input" type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    {/* Date input */}
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="date">Date:</label>
                        <input className="uk-input" type="date" id="date" value={date} onChange={(e) => setDate(e.target.value)} required />
                    </div>
                    {/* Start time input */}
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="startTime">Start Time:</label>
                        <input className="uk-input" type="time" id="startTime" value={startTime} onChange={(e) => setStartTime(e.target.value)} required />
                    </div>
                    {/* End time input */}
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="endTime">End Time:</label>
                        <input className="uk-input" type="time" id="endTime" value={endTime} onChange={(e) => setEndTime(e.target.value)} required />
                    </div>
                    {/* Notes input */}
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="notes">Notes:</label>
                        <textarea className="uk-textarea" id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} rows="4"></textarea>
                    </div>
                    {/* Tag input */}
                    <div className='mb-2 ' style={{ display: 'flex', gap: '25px', alignItems: 'end' }}>
                        <div className=''>
                            <label className="form-label">Tag:</label>
                            <input
                                type="text"
                                className="form-control bg-dark text-light"
                                name="tags"
                                value={tag}
                                onChange={handleTagsChange}
                                list="tagSuggestions" // Using the list attribute
                                autoComplete="off" // Disable browser's default autocomplete
                            />
                            <datalist id="tagSuggestions">
                                {/* Display filtered options */}
                                {filteredOptions.map((option, index) => (
                                    <option key={index} value={option} />
                                ))}
                            </datalist>
                        </div>
                        {/* Button to add tag */}
                        <div className=''>
                            <button type="button" onClick={addTag} className="btn btn-outline-light">Add Tag</button>
                        </div>
                    </div>
                    {/* Display added tags */}
                    <div className="d-flex flex-wrap mb-3">
                        {tags.map((tag, index) => (
                            <a key={index} onClick={() => removeTag(index)} href="#">
                                <span className="" style={{ fontSize: '20px', marginRight: '5px', borderRadius: '25px', padding: '7.5px', border: 'solid 5px #62B5E4' }}>{tag}</span>
                            </a>
                        ))}
                    </div>
                    {/* Form buttons */}
                    <div style={{ display: 'flex', justifyContent: 'space-around' }} className="">
                        <button className="uk-button uk-button-default" type="button">Cancel</button>
                        <button className="uk-button uk-button-primary" type="submit" disabled={!isFormValid}>{event ? 'Update Event' : 'Create Event'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateEventForm;
