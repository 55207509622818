import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Week({ date, setDate, events }) {

    const prevWeek = () => {
        setDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7));
    };

    const nextWeek = () => {
        setDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7));
    };

    function isSameDay(date1, date2) {
        date1 = new Date(date1);
        date2 = new Date(date2);

        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    const getWeekDays = () => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, date.getDate() - date.getDay()); // Adjusted to first day of the week
        const weekDays = [];

        for (let i = 0; i < 7; i++) {
            const currentDate = new Date(firstDay);
            currentDate.setDate(firstDay.getDate() + i);
            weekDays.push(currentDate);
        }

        return weekDays;
    };

    const weekDays = getWeekDays();

    const renderDays = () => {
        const days = weekDays.map((currentDate, index) => {
            const eventsOnDay = events.filter((event) => {
                return isSameDay(new Date(event.date), currentDate);
            });

            const badge = eventsOnDay.length > 0 ? <div style={{ margin: '0 auto', height: '25px', width: '25px', backgroundColor: '#0A5DC2', borderRadius: '50%', color: '#fff' }}>{eventsOnDay.length}</div> : null;
            let tileColor = '#fff';
            const year = date.getFullYear();
            const month = date.getMonth();
            const today = new Date(year, month, date.getDate());
            if (isSameDay(currentDate, new Date())) {
                tileColor = '#C5DEFC';
            }
            if (currentDate.getTime() === today.getTime()) {
                tileColor = '#9EC7FA';

            }
            if (isSameDay(currentDate, new Date()) && currentDate.getTime() === today.getTime()) {
                tileColor = '#8BBCF9';
            }
            return (
                <div
                    key={`day-${index}`}
                    style={{
                        flexBasis: '14%',
                        height: '50px',
                        backgroundColor: tileColor,
                        textAlign: 'center',
                        borderRight: '1px solid #ccc',
                        borderBottom: '1px solid #ccc',

                        cursor: 'pointer', // Adding cursor style for indication of clickable element
                        position: 'relative', // Position for badge
                    }}
                    className="calendar-day"
                    onClick={() => {
                        setDate(currentDate);
                    }}
                >
                    {currentDate.getDate()}
                    <br />
                    {badge}
                </div>
            );
        });

        return days;
    };


    return (
        <div className="calendar" style={{ maxWidth: '1000px', margin: '0 auto' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", padding: '25px' }}>

                <a href='#' onClick={prevWeek}><GrLinkPrevious size={'30px'} /></a>

                <div style={{ fontSize: '30px' }}>

                    {`${weekDays[0].toLocaleString('default', { month: 'long' })} ${weekDays[0].getDate()} - ${weekDays[6].getDate()}, ${weekDays[0].getFullYear()}`}
                </div>
                <a href='#' onClick={nextWeek}><GrLinkNext size={'30px'} /></a>
            </div>
            <div className="calendar-body">
                <div className="" style={{ display: 'flex' }}>
                    {
                        ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat'].map((dayOfWeek, index) => (
                            <div
                                key={index}
                                style={{
                                    flexBasis: '14%',
                                    backgroundColor: '#f7f7f7',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }}
                                className="calendar-day"
                            >
                                {dayOfWeek}
                            </div>
                        ))
                    }
                </div>
                <div className="calendar-row" style={{ display: 'flex', flexWrap: 'wrap' }}>{renderDays()}</div>
            </div>

        </div >
    );
}

export default Week;
