import React, { useState } from 'react';
import { CiEdit } from "react-icons/ci";
import CreateEventForm from './EventForm';
import { IoCloseOutline } from "react-icons/io5";


const EventViewer = ({ event, refresh }) => {
    const [editingEvent, setEditingEvent] = useState(false);
    // Format date
    function formatDate(date) {
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Format time
    const formatTime = (timeString) => {
        // Parse the time string
        var timeParts = timeString.split(":");
        var hours = parseInt(timeParts[0], 10);
        var minutes = parseInt(timeParts[1], 10);

        // Format the time
        var formattedTime;
        if (hours === 0) {
            formattedTime = "12:" + (minutes < 10 ? "0" : "") + minutes + " AM";
        } else if (hours < 12) {
            formattedTime = hours + ":" + (minutes < 10 ? "0" : "") + minutes + " AM";
        } else if (hours === 12) {
            formattedTime = "12:" + (minutes < 10 ? "0" : "") + minutes + " PM";
        } else {
            formattedTime = (hours - 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " PM";
        }

        return formattedTime;
    };

    return (
        <div className="event" style={{ width: '300px', padding: '25px', border: '5px solid #62B5E4', backgroundColor: '#DCEFF9', borderRadius: '25px', margin: '25px' }}>

            {
                editingEvent ?
                    <CreateEventForm onCreate={() => { refresh(); setEditingEvent(!editingEvent); }} initialDate={event.date} event={event} /> :
                    (

                        <div>
                            <div style={{}}>
                                {event.title}

                            </div>
                            <div>

                                {formatDate(event.date)}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{ backgroundColor: 'white', padding: '10px', borderRadius: '10px' }}>{formatTime(event.startTime)}</span>
                                <span style={{ backgroundColor: 'white', padding: '10px', borderRadius: '10px' }}>{formatTime(event.endTime)}</span>
                            </div>

                            <p> {event.tags && event.tags.map((tag, index) => (
                                <span key={index} style={{ fontSize: '20px', marginRight: '5px', borderRadius: '10px', padding: '7.5px', border: 'solid 2px #62B5E4', backgroundColor: 'white' }}>{tag}</span>
                            ))}</p>
                            <div style={{}}>
                                {event.notes}

                            </div>

                        </div>
                    )
            }
            <a href="#" onClick={() => setEditingEvent(!editingEvent)}>
                {
                    editingEvent ?
                        <IoCloseOutline size={'50px'} /> :
                        <CiEdit size={'50px'} />
                }
            </a>

        </div>
    );
};

export default EventViewer;
