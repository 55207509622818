import React, { useState, useEffect } from 'react';
import Calendar from './Calendar';

import CreateEventForm from './EventForm';
import axios from 'axios';
import EventViewer from './EventViewer';
import BottomNav from './BottomNav';
import SidewaysAnalogScrollingClock from './SidewaysClock';
import Week from './Week';
import { BsCalendar3, BsCalendar3Range } from "react-icons/bs";
import { MdOutlineCalendarViewDay } from "react-icons/md";



function App() {
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [showform, setShowForm] = useState(false);
  const [view, setView] = useState('Week');

  // Function to fetch all articles
  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://api.jeremiah.business/objectapi/event-tracker/events');
      const sorted = [...response.data.objects].sort((a, b) => new Date(b.date) - new Date(a.date));

      console.log(sorted);
      setEvents(sorted);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };
  useEffect(() => {
    // Fetch articles when the component mounts
    fetchEvents();
  }, []);

  function isSameDay(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);

    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  return (
    <div className="calendar" >

      <div style={{ display: 'flex', justifyContent: 'center', position: 'fixed', backgroundColor: 'white', zIndex: 1000, width: '100%' }}>
        <a href="#" style={{ textAlign: 'center', margin: '25px' }} onClick={() => setView('Month')}>
          <BsCalendar3 size={'25px'}
            style={{
              color: view === 'Month' ? '#0A5DC2' : 'grey'
            }} />
          <h4 style={{
            margin: 0, color: view === 'Month' ? '#0A5DC2' : 'grey'
          }}>Month</h4>
        </a>
        <a href="#" onClick={() => setView('Week')} style={{ textAlign: 'center', margin: '25px' }}>
          <BsCalendar3Range size={'25px'}
            style={{
              color: view === 'Week' ? '#0A5DC2' : 'grey'
            }} />
          <h4 style={{
            margin: 0, color: view === 'Week' ? '#0A5DC2' : 'grey'
          }}>Week</h4>
        </a>
        <a href="#" onClick={() => setView('All')} style={{ textAlign: 'center', margin: '25px' }}>
          <MdOutlineCalendarViewDay size={'25px'}
            style={{
              color: view === 'All' ? '#0A5DC2' : 'grey'
            }} />
          <h4 style={{
            margin: 0, color: view === 'All' ? '#0A5DC2' : 'grey'
          }}>View All</h4>
        </a>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', visibility: 'hidden' }}>
        <div href="#" style={{ textAlign: 'center', margin: '25px' }} onClick={() => setView('Month')}>
          <BsCalendar3 size={'25px'}
            style={{
              color: view === 'Month' ? '#0A5DC2' : 'grey'
            }} />
          <h4 style={{
            margin: 0, color: view === 'Month' ? '#0A5DC2' : 'grey'
          }}>Month</h4>
        </div>

      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
        <div style={{ fontSize: '25px' }}>

          {date.toDateString()}
          { }
        </div>
        {
          !showform &&
          <a href="#" onClick={() => setShowForm(!showform)} uk-icon="icon: plus-circle; ratio: 2" style={{ color: '#62B5E4' }}></a>
        }
        {
          showform &&
          <a href="#" onClick={() => setShowForm(!showform)} uk-icon="icon: close; ratio: 2" style={{ color: '#62B5E4' }}></a>
        }
        <a href="#" onClick={() => fetchEvents()} uk-icon="icon: refresh; ratio: 2" style={{ color: '#62B5E4' }}></a>

      </div>

      {
        view === 'All' ?
          (
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', fontSize: '25px' }}>

              {
                events.map((event, index) => {


                  return (
                    <EventViewer refresh={() => fetchEvents} key={index} event={event} />
                  );
                })
              }
            </div>

          )
          :
          view === 'Week' ?
            <Week events={events} date={date} setDate={setDate} /> :
            <Calendar events={events} date={date} setDate={setDate} />

      }
      {
        showform &&
        <dialog open style={{
          width: '300px',
          backgroundColor: 'white',
          border: '5px solid #62B5E4',
          padding: '25px',
          borderRadius: '25px',
          boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.1)',
        }}>

          {showform && (


            <CreateEventForm onCreate={() => { fetchEvents(); setShowForm(!showform); }} initialDate={date} />

          )}
        </dialog>

      }
      <style>
        {`
          dialog::backdrop {
            background-color: rgba(150, 100, 200, 0.5);
          }
        `}
      </style>

      {

      }
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', fontSize: '25px' }}>

        {
          view !== 'All' &&
          events.map((event, index) => {

            if (!isSameDay(new Date(date), new Date(event.date))) {
              return <p></p>

            }
            return (
              <EventViewer refresh={() => fetchEvents} key={index} event={event} />
            );
          })
        }
      </div>













    </div >
  );
}

export default App;
