import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Calendar({ date, setDate, events }) {

    const prevMonth = () => {
        setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
    };

    function isSameDay(date1, date2) {
        date1 = new Date(date1);
        date2 = new Date(date2);

        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    const getMonthDays = () => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1).getDay();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const monthDays = [];

        for (let i = 1; i <= daysInMonth; i++) {
            monthDays.push(i);
        }

        return { firstDay, monthDays };
    };

    const { firstDay, monthDays } = getMonthDays();

    const renderDays = () => {
        const { firstDay, monthDays } = getMonthDays();
        const year = date.getFullYear();
        const month = date.getMonth();
        const today = new Date(year, month, date.getDate()); // Get today's date

        const blanks = [];
        for (let i = 0; i < firstDay; i++) {
            blanks.push(
                <div
                    key={`blank-${i}`}
                    style={{
                        flexBasis: '14%',
                        height: '50px',
                        backgroundColor: '#EAECEC',
                        textAlign: 'center',
                        borderRight: '1px solid #ccc',
                        borderBottom: '1px solid #ccc',
                    }}
                    className="calendar-day empty"
                ></div>
            );
        }

        const days = monthDays.map((day) => {
            const currentDate = new Date(year, month, day);
            const eventsOnDay = events.filter((event) => {
                console.log(Date(event.date) + '     ' + currentDate);
                return isSameDay(event.date, currentDate);
            });

            const badge = eventsOnDay.length > 0 ? <div style={{ margin: '0 auto', height: '25px', width: '25px', backgroundColor: '#0A5DC2', borderRadius: '50%', color: '#fff' }}>{eventsOnDay.length}</div> : null;
            let tileColor = '#fff';
            if (isSameDay(currentDate, new Date())) {
                tileColor = '#C5DEFC';
            }
            if (currentDate.getTime() === today.getTime()) {
                tileColor = '#9EC7FA';

            }
            if (isSameDay(currentDate, new Date()) && currentDate.getTime() === today.getTime()) {
                tileColor = '#8BBCF9';
            }
            return (
                <div
                    key={`day-${day}`}
                    style={{
                        flexBasis: '14%',
                        height: '50px',
                        backgroundColor: tileColor,
                        textAlign: 'center',
                        borderRight: '1px solid #ccc',
                        borderBottom: '1px solid #ccc',

                        cursor: 'pointer', // Adding cursor style for indication of clickable element
                        position: 'relative', // Position for badge
                    }}
                    className="calendar-day"
                    onClick={() => {
                        setDate(currentDate);
                    }}
                >
                    {day}
                    <br />
                    {badge}
                </div>
            );
        });

        return [...blanks, ...days];
    };


    return (
        <div className="calendar" style={{ maxWidth: '1000px', margin: '0 auto' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", padding: '25px' }}>


                <a href='#' onClick={prevMonth}><GrLinkPrevious size={'30px'} /></a>

                <div style={{ fontSize: '30px' }}>

                    {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
                </div>
                <a href='#' onClick={nextMonth}><GrLinkNext size={'30px'} /></a>
            </div>
            <div className="calendar-body">
                <div className="" style={{ display: 'flex', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', }}>
                    {
                        ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat'].map((dayOfWeek, index) => (
                            <div
                                key={index}
                                style={{
                                    flexBasis: '14%',
                                    backgroundColor: '#f7f7f7',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }}
                                className="calendar-day"
                            >
                                {dayOfWeek}
                            </div>
                        ))
                    }
                </div>
                <div className="calendar-row" style={{ display: 'flex', flexWrap: 'wrap', borderLeft: '1px solid #ccc', }}>{renderDays()}</div>
            </div>

        </div >
    );
}

export default Calendar;
